import { classNames } from "~/utils";
import React from "react";
import Markdown from "marked-react";
import { Notification } from "~/graphql/__generated";

type NotificationProps = {
  notification: Notification;
};

export const NotificationContent = React.forwardRef<
  HTMLDivElement,
  NotificationProps
>(({ notification }, ref) => {
  const formatElapsedTime = (createdAt: string) => {
    const currentTime = new Date();
    const createdTime = new Date(createdAt);
    const elapsedMilliseconds = currentTime.getTime() - createdTime.getTime();

    const elapsedMins = elapsedMilliseconds / (1000 * 60);
    const elapsedHours = elapsedMilliseconds / (1000 * 60 * 60);
    const elapsedDays = elapsedMilliseconds / (1000 * 60 * 60 * 24);
    const elapsedWeeks = elapsedMilliseconds / (1000 * 60 * 60 * 24 * 7);

    if (elapsedMins < 10) {
      return "now";
    }

    if (elapsedHours < 1) {
      return "the last hour";
    }

    if (elapsedHours < 24) {
      return Math.round(elapsedHours) + "h";
    } else if (elapsedDays < 14) {
      return Math.round(elapsedDays) + "d";
    } else {
      return Math.round(elapsedWeeks) + "w";
    }
  };

  return (
    <div
      className="flex items-center pt-1.5 space-x-2"
      ref={ref}
      id={notification.id}
      data-read={notification.read}
    >
      <div
        className={classNames(
          "h-2 w-2 rounded-full flex-none",
          notification.read ? "" : "bg-theme-text-error",
        )}
      ></div>
      {notification.icon && (
        <img
          className="h-8 w-8 rounded-full flex-none"
          src={notification.icon}
        />
      )}
      {!notification.icon && (
        <div className="h-8 w-8  rounded-full flex-none" />
      )}
      <div className="py-2 flex flex-col">
        <div className="[&>*]:font-bold">
          <Markdown>{notification.title}</Markdown>
        </div>
        <div className="[&>*]:text-sm">
          <Markdown>{notification.body || ""}</Markdown>
        </div>
        <div className="text-sm text-placeholder-foreground">
          {formatElapsedTime(notification.createdAt)}
        </div>
      </div>
    </div>
  );
});

export default NotificationContent;
